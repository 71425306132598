//
// Flex Utilities
//

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .flex#{$infix}-root {
      flex: 1;
    }

    .flex#{$infix}-column-auto {
      flex: none;
    }

    .flex#{$infix}-column-fluid {
      flex: 1 0 auto;
    }

    .flex#{$infix}-row-auto {
      flex: 0 0 auto;
    }

    .flex#{$infix}-row-fluid {
      flex: 1 auto;
      min-width: 0;
    }

    .flex#{$infix}-center {
      justify-content: center;
      align-items: center;
    }

    .flex#{$infix}-start {
      justify-content: start;
      align-items: start;
    }

    .flex#{$infix}-end {
      justify-content: flex-end;
      align-items: flex-end;
    }

    .flex#{$infix}-stack {
      justify-content: space-between;
      align-items: center;
    }
  }
}

.flex-equal {
  flex-grow: 1;
  flex-basis: 0;
  flex-shrink: 0;
}
