.toast {
  .toast-header {
    .btn-close {
      margin-right: 0;
    }
  }
}

@if $enable-dark-mode {
  @include color-mode(dark) {
    .toast {
      --#{$prefix}toast-bg: #{$toast-background-color-dark};
      --#{$prefix}toast-header-bg: #{$toast-header-background-color-dark};
      --#{$prefix}toast-header-border-color: #{$toast-header-border-color-dark};
    }
  }
}
