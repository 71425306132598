//
// 3rd-party config
//

// Prismjs
$prismjs-bg: #1e1e3f;

$prismjs-border: rgba($white, 0.1);

$prismjs-btn-bg: lighten($prismjs-bg, 9%);
$prismjs-btn-bg-hover: lighten($prismjs-bg, 9%);

$prismjs-btn-color: rgba($white, 0.75);
$prismjs-btn-color-hover: $primary;

$prismjs-scrollbar-color: lighten($prismjs-bg, 12%);
$prismjs-scrollbar-color-hover: lighten($prismjs-bg, 15%);

// Bootstrap Maxlength
$bootstrap-maxlength-z-index: 1040;

// Daterangepicker
$daterangepicker-ranges-list-height: 260px;
$daterangepicker-ranges-list-width: 150px;
