//
// Custom background helpers
//

// Background white color
.bg-white {
  --#{$prefix}bg-rgb-color: var(--#{$prefix}white-bg-rgb);
  background-color: $white !important;
}

// Background black color
.bg-black {
  --#{$prefix}bg-rgb-color: var(--#{$prefix}black-bg-rgb);
  background-color: $black !important;
}

// Background body color
.bg-body {
  --#{$prefix}bg-rgb-color: var(--#{$prefix}body-bg-rgb);
  background-color: var(--#{$prefix}body-bg) !important;
}

.bg-hover-body {
  cursor: pointer;

  &:hover {
    background-color: var(--#{$prefix}body-bg) !important;
  }
}

.bg-active-body {
  &.active {
    background-color: var(--#{$prefix}body-bg) !important;
  }
}

.bg-state-body {
  cursor: pointer;

  &.active,
  &:hover {
    background-color: var(--#{$prefix}body-bg) !important;
  }
}

.bg-hover-white {
  cursor: pointer;

  &:hover {
    --#{$prefix}bg-rgb-color: var(--#{$prefix}white-bg-rgb);
    background-color: $white !important;
  }
}

.bg-active-white {
  &.active {
    --#{$prefix}bg-rgb-color: var(--#{$prefix}white-bg-rgb);
    background-color: $white !important;
  }
}

.bg-state-white {
  cursor: pointer;

  &.active,
  &:hover {
    --#{$prefix}bg-rgb-color: var(--#{$prefix}white-bg-rgb);
    background-color: $white !important;
  }
}

// Background theme light colors
@each $name, $value in $theme-colors {
  @if (theme-light-color($name)) {
    .bg-light-#{$name} {
      background-color: var(--#{$prefix}#{$name}-light) !important;
    }
  }

  .bg-#{$name} {
    --#{$prefix}bg-rgb-color: var(--#{$prefix}#{$name}-rgb);
    background-color: var(--#{$prefix}#{$name}) !important;
  }

  .bg-#{$name}-active {
    --#{$prefix}bg-rgb-color: var(--#{$prefix}#{$name}-active-rgb);
    background-color: var(--#{$prefix}#{$name}-active) !important;
  }

  @if (theme-light-color($name)) {
    .bg-hover-light-#{$name} {
      cursor: pointer;

      &:hover {
        background-color: var(--#{$prefix}#{$name}-light) !important;
      }
    }

    .bg-state-light-#{$name} {
      cursor: pointer;

      &.active,
      &:hover {
        background-color: var(--#{$prefix}#{$name}-light) !important;
      }
    }
  }

  .bg-hover-#{$name} {
    cursor: pointer;

    &:hover {
      background-color: var(--#{$prefix}#{$name}) !important;
    }
  }

  .bg-active-#{$name} {
    &.active {
      background-color: var(--#{$prefix}#{$name}) !important;
    }
  }

  .bg-state-#{$name} {
    cursor: pointer;

    &.active,
    &:hover {
      background-color: var(--#{$prefix}#{$name}) !important;
    }
  }
}

// Background gray colors
@each $name, $value in $grays {
  .bg-gray-#{$name} {
    --#{$prefix}bg-rgb-color: var(--#{$prefix}gray-#{$name}-rgb);
    background-color: var(--#{$prefix}gray-#{$name});
  }

  .bg-gray-#{$name}i {
    --#{$prefix}bg-rgb-color: var(--#{$prefix}gray-#{$name}-rgb);
    background-color: var(--#{$prefix}gray-#{$name}) !important;
  }
}

// Opacity
@each $name, $value in $opacity-values {
  .bg-opacity-#{$name} {
    background-color: rgba(var(--#{$prefix}bg-rgb-color), #{$value}) !important;
  }

  .bg-hover-opacity-#{$name}:hover {
    background-color: rgba(var(--#{$prefix}bg-rgb-color), #{$value}) !important;
  }

  .bg-active-opacity-#{$name}.active {
    background-color: rgba(var(--#{$prefix}bg-rgb-color), #{$value}) !important;
  }

  .bg-state-opacity-#{$name} {
    .active,
    &:hover {
      background-color: rgba(var(--#{$prefix}bg-rgb-color), #{$value}) !important;
    }
  }
}

// Background image helpers
// Background repeat
.bgi-no-repeat {
  background-repeat: no-repeat;
}

// Background positions
.bgi-position-y-top {
  background-position-y: top;
}

.bgi-position-y-bottom {
  background-position-y: bottom;
}

.bgi-position-y-center {
  background-position-y: center;
}

.bgi-position-x-start {
  background-position-x: left;
}

.bgi-position-x-end {
  background-position-x: right;
}

.bgi-position-x-center {
  background-position-x: center;
}

.bgi-position-top {
  background-position: 0 top;
}

.bgi-position-bottom {
  background-position: 0 bottom;
}

.bgi-position-center {
  background-position: center;
}

// Responsive helpers
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    // Background sizes
    .bgi-size#{$infix}-auto {
      background-size: auto;
    }

    .bgi-size#{$infix}-cover {
      background-size: cover;
    }

    .bgi-size#{$infix}-contain {
      background-size: contain;
    }

    // Background attachment
    .bgi-attachment#{$infix}-fixed {
      background-attachment: fixed;
    }

    .bgi-attachment#{$infix}-scroll {
      background-attachment: scroll;
    }
  }
}
