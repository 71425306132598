//
// Multistep stepper
//

// Custom
.stepper.stepper-pills.stepper-multistep {
  --#{$prefix}stepper-pills-size: 46px;
  --#{$prefix}stepper-icon-border-radius: 9px;
  --#{$prefix}stepper-icon-check-size: 1.25rem;

  --#{$prefix}stepper-icon-bg-color: rgba(255, 255, 255, 0.03);
  --#{$prefix}stepper-icon-bg-color-current: var(--#{$prefix}success);
  --#{$prefix}stepper-icon-bg-color-completed: rgba(255, 255, 255, 0.03);

  --#{$prefix}stepper-icon-border: 1px dashed rgba(255, 255, 255, 0.3);
  --#{$prefix}stepper-icon-border-current: 0;
  --#{$prefix}stepper-icon-border-completed: 1px dashed rgba(255, 255, 255, 0.3);

  --#{$prefix}stepper-icon-number-color: var(--#{$prefix}white);
  --#{$prefix}stepper-icon-number-color-current: var(--#{$prefix}white);
  --#{$prefix}stepper-icon-number-color-completed: var(--#{$prefix}white);

  --#{$prefix}stepper-icon-check-color-completed: var(--#{$prefix}success);

  --#{$prefix}stepper-label-title-opacity: 0.7;
  --#{$prefix}stepper-label-title-opacity-current: 1;
  --#{$prefix}stepper-label-title-opacity-completed: 1;

  --#{$prefix}stepper-label-title-color: var(--#{$prefix}white);
  --#{$prefix}stepper-label-title-color-current: var(--#{$prefix}white);
  --#{$prefix}stepper-label-title-color-completed: var(--#{$prefix}white);

  --#{$prefix}stepper-label-desc-opacity: 0.7;
  --#{$prefix}stepper-label-desc-opacity-current: 0.7;
  --#{$prefix}stepper-label-desc-opacity-completed: 0.7;

  --#{$prefix}stepper-label-desc-color: var(--#{$prefix}white);
  --#{$prefix}stepper-label-desc-color-current: var(--#{$prefix}white);
  --#{$prefix}stepper-label-desc-color-completed: var(--#{$prefix}white);

  --#{$prefix}stepper-line-border: 1px dashed rgba(255, 255, 255, 0.3);
}
